import { AlertProps, Alert } from "@mui/material";

export function AlertBox({
  message,
  severity,
  ...props
}: AlertProps & { message: string }) {
  return (
    <Alert severity={severity} {...props}>
      {message}
    </Alert>
  );
}
