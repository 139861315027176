import { Search } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";

export function SearchInput({
  searchQuery,
  setSearchQuery,
}: {
  searchQuery: string;
  setSearchQuery: Function;
}): JSX.Element {
  return (
    <TextField
      variant="outlined"
      type="text"
      value={searchQuery}
      fullWidth
      onChange={(event) => setSearchQuery(event.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
      placeholder="Search..."
    />
  );
}
