import { useState } from "react";
import "./App.css";
import { SearchInput } from "./components/search";
import {
  AppBar,
  CircularProgress,
  Grid,
  Toolbar,
  Typography,
} from "@mui/material";
import { SearchButton } from "./components/button";
import { AlertBox } from "./components/alert";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.js`;

type QueryAnswer = {
  answer: string;
  source: string;
  chapter: string;
  start_timestamp: number;
  end_timestamp: number;
  language: string;
  type: "video" | "pdf";
  page: number;
  stay_tuned_id: string;
};

type QueryResult = {
  question: string;
  answers: QueryAnswer[];
};

type Chapter = {
  title: string;
  timecode: number;
};

type Video = {
  id: string;
  title: string;
  chapters: Chapter[];
  release_date: string;
  stay_tuned_id: string;
  vimeo_id: string;
  language: string;
  created_at: string;
  updated_at: string;
};

type StayTuned = {
  id: string;
  name: string;
  quarter: number;
  year: number;
};

type ErrorResponse = {
  statusCode: number;
  message: string;
};

const LAMBDA_BASE_URL =
  "https://x6zbh5184l.execute-api.eu-west-1.amazonaws.com";

function App() {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<QueryAnswer[]>([]);
  const [vimeoUrls, setVimeoUrls] = useState<
    { url: string; videoId: string }[]
  >([]);
  const [pdfs, setPdfs] = useState<
    { url: string; page: number; pdfId: string }[]
  >([]);
  const [stayTunedEditions, setStayTunedEditions] = useState<StayTuned[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [errorSeverity, setErrorSeverity] = useState<
    "error" | "warning" | "info" | "success" | undefined
  >(undefined);

  function handleUpdateQuery(query: string) {
    setSearchQuery(query);
  }

  async function handleStayTuned(stayTunedId: string): Promise<void> {
    const response = await fetch(
      `${LAMBDA_BASE_URL}/get-stay-tuned/${stayTunedId}`,
      {
        method: "GET",
      }
    );

    const stayTuned = (await response.json()) as StayTuned;

    if (!stayTunedEditions.find((s) => s.id === stayTuned.id)) {
      const editions = [...stayTunedEditions, stayTuned];

      setStayTunedEditions(editions);
    }
  }

  async function handlePDF(pdfId: string, page: number): Promise<void> {
    const response = await fetch(`${LAMBDA_BASE_URL}/get-pdf/${pdfId}`, {
      method: "GET",
    });

    const pdf = (await response.json()) as { url: string };

    const urls = [
      ...pdfs,
      {
        url: pdf.url,
        page,
        pdfId,
      },
    ];

    setPdfs(urls);
  }

  async function handleVimeoUri(
    videoId: string,
    start_timestamp: number
  ): Promise<void> {
    const response = await fetch(`${LAMBDA_BASE_URL}/get-video/${videoId}`, {
      method: "GET",
    });

    const video = (await response.json()) as Video;

    const urls = [
      ...vimeoUrls,
      {
        videoId,
        url: `https://player.vimeo.com/video/${video.vimeo_id}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479#t=${start_timestamp}`,
      },
    ];

    setVimeoUrls(urls);
  }

  async function handleSearch() {
    setLoading(true);

    try {
      const response = await fetch(`${LAMBDA_BASE_URL}/search`, {
        method: "POST",
        body: JSON.stringify({ query: searchQuery }),
      });

      if (response.status >= 400 && response.status < 500) {
        const error = await response.json();
        setError(`An error occurred while searching: ${error.message}`);
        setErrorSeverity("warning");
        setLoading(false);
        return;
      }

      if (response.status >= 500) {
        setError(`An error occurred on the server`);
        setErrorSeverity("error");
        setLoading(false);
        return;
      }

      const data = await response.json();

      if (!data) {
        setError("No results found");
        setErrorSeverity("info");
        setLoading(false);
        return;
      }

      const { answers } = data as QueryResult;

      for (const answer of answers) {
        await Promise.all([
          ...(answer.type === "video"
            ? [handleVimeoUri(answer.source, answer.start_timestamp)]
            : []),
          ...(answer.type === "pdf"
            ? [handlePDF(answer.source, answer.page)]
            : []),
          ...(answer.stay_tuned_id
            ? [handleStayTuned(answer.stay_tuned_id)]
            : []),
        ]);
      }
      setSearchResults(answers);
    } catch (error: unknown) {
      setError(
        `An error occurred while searching: ${(error as ErrorResponse).message}`
      );
      setErrorSeverity("error");
      setLoading(false);
      return;
    }

    setLoading(false);
  }

  return (
    <div className="App">
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div">
            DPI: Project Guy
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        <section className="search-grid">
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={10}>
              <SearchInput
                searchQuery={searchQuery}
                setSearchQuery={handleUpdateQuery}
              />
            </Grid>
            <Grid item xs={2}>
              <SearchButton onClick={handleSearch} />
            </Grid>
          </Grid>
        </section>
        <section className="search-results">
          <p>
            <strong>DISCLAIMER</strong>: The answers to your question are
            generated based on what the videos and powerpoints provide around
            the subject in your question. Don't copy these answers but rather
            refer to the videos and powerpoints.
          </p>
          {error && (
            <AlertBox
              message={error}
              severity={errorSeverity}
              onClose={() => setError("")}
            />
          )}
          {loading && <CircularProgress />}
          {!loading &&
            searchResults.map((result, index) => (
              <div key={index}>
                <h3>
                  {
                    stayTunedEditions.find(
                      (st) => st.id === result.stay_tuned_id
                    )?.name
                  }
                </h3>
                <h4>{result.chapter}</h4>
                <p>{result.answer}</p>
                {vimeoUrls.length > 0 &&
                  vimeoUrls.find((v) => v.videoId === result.source) && (
                    <iframe
                      key={index}
                      src={
                        vimeoUrls.find((v) => v.videoId === result.source)?.url
                      }
                      width="640"
                      height="360"
                      title="Vimeo video player"
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowFullScreen
                    />
                  )}
                {pdfs.length > 0 &&
                  pdfs.find(
                    (p) => p.pdfId === result.source && p.page === result.page
                  ) && (
                    <Document
                      file={
                        pdfs.find(
                          (p) =>
                            p.pdfId === result.source && p.page === result.page
                        )?.url ?? ""
                      }
                    >
                      <Page
                        pageNumber={
                          pdfs.find(
                            (p) =>
                              p.pdfId === result.source &&
                              p.page === result.page
                          )?.page
                        }
                        canvasBackground="transparent"
                        renderTextLayer={false}
                      />
                    </Document>
                  )}
              </div>
            ))}
        </section>
      </main>
    </div>
  );
}

export default App;
